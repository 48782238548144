<template>
  <div class="home-search">
    <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">信贷产品搜索</div>
    <div class="header flex">
      <div class="search-box flex">
        <img src="../assets/img/serch.png" @click="searchSubmit">
        <input type="text" placeholder="点击进入信贷产品搜索" v-model="searchVal" @keyup.enter="searchSubmit">
      </div>
    </div>

    <div class="bank-goods">
      <div class="flex">
      <van-dropdown-menu class="down-menu" :overlay="false" active-color="#ff9900">
        <van-dropdown-item title="银行" ref="items">
          <div class="down-menu-items">
            <van-checkbox-group v-model="checkBank" direction="horizontal" @click="checkBankChange(checkBank)">
            <van-checkbox name="不限">不限</van-checkbox>
            <van-checkbox name="工商银行">工商银行</van-checkbox>
            <van-checkbox name="农业银行">农业银行</van-checkbox>
            <van-checkbox name="中国银行">中国银行</van-checkbox>
            <van-checkbox name="建设银行">建设银行</van-checkbox>
            <van-checkbox name="邮储银行">邮储银行</van-checkbox>
            <van-checkbox name="交通银行">交通银行</van-checkbox>
            <van-checkbox name="柳州银行">柳州银行</van-checkbox>
            <van-checkbox name="桂林银行">桂林银行</van-checkbox>
            <van-checkbox name="北部湾银行">北部湾银行</van-checkbox>
            <van-checkbox name="民生银行">民生银行</van-checkbox>
            <van-checkbox name="招商银行">招商银行</van-checkbox>
            <van-checkbox name="浦发银行">浦发银行</van-checkbox>
            <van-checkbox name="光大银行">光大银行</van-checkbox>
            <van-checkbox name="兴业银行">兴业银行</van-checkbox>
            <van-checkbox name="华夏银行">华夏银行</van-checkbox>
            <van-checkbox name="广发银行">广发银行</van-checkbox>
            <van-checkbox name="国开银行">国开银行</van-checkbox>
            <van-checkbox name="农发银行">农发银行</van-checkbox>
            <van-checkbox name="渤海银行">渤海银行</van-checkbox>
            <van-checkbox name="农信联社">农信联社</van-checkbox>
            <van-checkbox name="村镇银行">村镇银行</van-checkbox>
            </van-checkbox-group>
            <button class="check_ok" @click="checkConfirm(0)">确定</button>
          </div>          
        </van-dropdown-item>
        <van-dropdown-item title="类型" ref="items2">
          <div class="down-menu-items">
            <van-checkbox-group v-model="value2" direction="horizontal" @click="checkValue2Change(value2)">
              <van-checkbox name="不限">不限</van-checkbox>
              <van-checkbox name="贷款">贷款</van-checkbox>
              <van-checkbox name="票据贴现">票据贴现</van-checkbox>
              <van-checkbox name="贸易融资">贸易融资</van-checkbox>
              <van-checkbox name="银行承兑汇票">银行承兑汇票</van-checkbox>
              <van-checkbox name="信用证">信用证</van-checkbox>
              <van-checkbox name="保函">保函</van-checkbox>
              <van-checkbox name="保理">保理</van-checkbox>
              <van-checkbox name="其他">其他</van-checkbox>
            </van-checkbox-group>
            <button class="check_ok" @click="checkConfirm(1)">确定</button>
          </div>
        </van-dropdown-item>
        <van-dropdown-item v-model="value3" :options="option3" />
      </van-dropdown-menu>
      <div class="screen" @click="showPopup">
        <span>全部筛选</span>
        <img src="../assets/img/screen.png" alt="">
        </div>
      </div>       
    </div>
    <van-popup class="sizer" v-model:show="show" round position="top" :style="{ height: '90%' }">
      <Sizer @searchClick="searchClick"/>
    </van-popup>
    <div class="goods-info">
      <div class="goods">
        <div class="goods-header flex">
          <img src="../assets/img/zgyhlogo.png" alt="">
          <p class="flex1">抗疫贷</p>
          <p class="gray">发放款10笔</p>
        </div>
        <div class="goods-content flex">
          <img src="../assets/img/yhcp1.png" alt="">
          <div class="flex-col">
            <p>融资额度：100万</p>
            <p>融资金额期限：12个月</p>
            <p>融资利率：3%~5%</p>
          </div>
            <div class="star flex">
              <img src="../assets/img/star.png" alt="">
              <img src="../assets/img/star.png" alt="">
              <img src="../assets/img/star.png" alt="">
            </div>
            <div class="share flex" @click="showShare = true">
              <img src="../assets/img/share.png" alt="">
              <p>分享</p>
            </div>
            <div class="apply">我要申请</div>
        </div>
      </div>
    </div>
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="goods-info" v-for="item in list" :key="item">
      <div class="goods">
        <div class="goods-header flex">
          <img src="../assets/img/zgyhlogo.png" alt="">
          <p class="flex1">抗疫贷</p>
          <p class="gray">发放款10笔</p>
        </div>
        <div class="goods-content flex">
          <img src="../assets/img/yhcp1.png" alt="">
          <div class="flex-col">
            <p>融资额度：100万</p>
            <p>融资金额期限：12个月</p>
            <p>融资利率：3%~5%</p>
          </div>
            <div class="star flex">
              <img src="../assets/img/star.png" alt="">
              <img src="../assets/img/star.png" alt="">
              <img src="../assets/img/star.png" alt="">
            </div>
            <div class="share flex" @click="showShare = true">
              <img src="../assets/img/share.png" alt="">
              <p>分享</p>
            </div>
            <div class="apply">我要申请</div>
        </div>
      </div>
    </div>
    </van-list>

    <van-share-sheet
  v-model:show="showShare"
  title="立即分享给好友"
  :options="shareOptions"
  @select="onSelect"
/>

  </div>
</template>

<script>
import Sizer from '../components/Sizer.vue'
import { ref } from 'vue';
export default {

  setup() {
    const items = ref(null);
    const items2 = ref(null);
    const checkBank = ref(['不限']);
    const value2 = ref(['不限']);
    const value3 = ref(0);
    const searchVal = ref('');
    const option3 = [
      { text: '金额 ', value: 0 },
      { text: '0~50万', value: 1 },
      { text: '50~100万', value: 2 },
      { text: '100~500万', value: 3 },
      { text: '500万以上', value: 4 },
    ];
    const show = ref(false);
    const showPopup = () => {
      show.value = true;
    };
    const showShare = ref(false);
    const shareOptions = [
      { name: '微信', icon: 'wechat' },
      { name: '微博', icon: 'weibo' },
      { name: '复制链接', icon: 'link' },
      { name: '二维码', icon: 'qrcode' },
    ];
    const onSelect = (option) => {
      console.log(option.name);
      showShare.value = false;
    };
    //检查不限
    const checkBankChange = (val) =>{
      if(val[val.length - 1] === '不限') {
          checkBank.value = ['不限'];
        }else {
          const index = val.findIndex(item => item === '不限');
          if(index > -1) {
            checkBank.value.splice(index, 1);
          }
        }
    }
    const checkValue2Change = (val) =>{
      if(val[val.length - 1] === '不限') {
          value2.value = ['不限'];
        }else {
          const index = val.findIndex(item => item === '不限');
          if(index > -1) {
            value2.value.splice(index, 1);
          }
        }
    }
    const checkConfirm = (i) => {
      if(!i) {
        console.log(checkBank.value,'选择银行');
        items.value.toggle();
      }else {
        console.log(value2.value,'选择类型');
        items2.value.toggle();
      }      
    };

    function searchClick (searCon) {
      console.log('全部筛选子组件的值',searCon)
      show.value = false;
      
    }
    //搜索结果列表list
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false); 
    const onLoad = () => {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 5; i++) {
          list.value.push(list.value.length + 1);
        }
        // 加载状态结束
        loading.value = false;
        // 数据全部加载完成
        if (list.value.length >= 10) {
          finished.value = true;
        }
      }, 1000);
    };

    //搜索框键入
    const searchSubmit = () => {
      console.log('搜索值为：',searchVal.value);
    };
    return {
      items,
      items2,
      checkBank,
      checkBankChange,
      checkValue2Change,
      checkConfirm,
      value2,
      value3,
      option3,
      show,
      showPopup,
      shareOptions,
      onSelect,
      showShare,
      searchClick,
      list,
      onLoad,
      loading,
      finished,
      searchVal,
      searchSubmit
    };
  },
  methods:{
      goBack(){
          this.$router.go(-1);
      },
  },
  components: {
    Sizer
  }
}
</script>
<style scoped lang="less">
.home-search {
  padding-top: 12.5vw;
  font-size: 4vw;
  .header {
    background: linear-gradient(to right, #65b8fc, #8193ff);
    padding: 0 4vw 2vw; 
  }
  .bank-goods {
    background-color: #fff;
    p {
      margin: 0;
      padding-bottom: 2vw;
      font-size: 5vw;
      font-weight: 600;
    }
    .down-menu {
      width: 70%;
      // van-dropdown-item {
      //   width: 20%;
      // }
      .down-menu-items {
        padding: 4vw;
        p {
          font-weight: 600;
          margin-bottom: 1vw;
          font-size: 4vw;
        }
        select {
          background-color: #f5f5f5;
          border: 0;
          width: 100%;
          border: 0;
          padding: 3vw 2vw;
          font-size: 4vw;
          border-radius: 1vw;
          box-sizing: border-box;
       }
      }
    }
    .flex {
      height: var(--van-dropdown-menu-height);
      background-color: var(--van-dropdown-menu-background-color);
      box-shadow: var(--van-dropdown-menu-box-shadow);
      .screen {
      flex: 1;
      height: 48px;
      font-size: 3.5vw;
      text-align: center;
      line-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 3.5vw;
        width: auto;
        margin-left: 1vw;
      }
    }
    }
    
  }

  .goods-info {
    margin: 3vw 4vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 3vw 2.5vw;
    .goods-header {
      margin-bottom: 2vw;
      img {
        width: 28.6vw;
        height: 8vw
      };
      .flex1 {
        flex: 1;
        margin-left: 2vw;
        font-size: 4.8vw;
        color: #6699ff;
        font-weight: 600;
      }
      .gray {
        font-size: 3.2vw;
        color: #666666;
      }
    }
    .goods-content {
      position: relative;
      img {
        height: 18.7vw;
        width: 28.6vw;
      }
      .flex-col {
        margin-left: 2vw;
        p {
          margin: 0;
          font-size: 3.2vw;
          color: #666666;
          margin: 0.5vw;
        }
      }
      .star {
        position: absolute;
        top: -2vw;
        right: 0;
          img {
            width: 4vw;
            height: 4vw;
            margin-left: 1vw;
          }
      }
      .share {
        position: absolute;
        top: 4vw;
        right: 0;
        border: 1px solid #ff9900;
        padding: 1vw 3vw;
        border-radius: 4vw;
          img {
            width: 3.6vw;
            height: auto;
            margin-right: 1vw;
          }
          p {
            color: #ff9900;
            font-size: 3.2vw;
          }
      }
      .apply {
        position: absolute;
        bottom: 0;
        right: 0;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 3.5vw;
        padding: 1vw 2vw;
        border-radius: 4vw;
      }

    }

  }
}
</style>
<style lang="less">
.home-search .bank-goods .van-popup--top {
    left: 0;
    width: 100%;
}
</style>